import React from 'react';
import Sprite from '../../sprites/sprite';

interface ModalCloseButtonProps {
  onClose: VoidFunction;
}
export const ModalCloseButton: React.FC<ModalCloseButtonProps> = ({ onClose }) => {
  return (
    <button
      onClick={onClose}
      className="w-10 h-10 z-10 rounded-full bg-gray-100 flex flex-col items-center justify-center cursor-pointer absolute top-2 right-2 md:-top-5 md:-right-5"
    >
      <Sprite name="close" className="w-3" strokeWidth={4} width="25" height="24" />
    </button>
  );
};
