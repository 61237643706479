import { TentTypeV1 } from '@camp67/model';

export const getPrice = (tentType: TentTypeV1) => {
  switch (tentType) {
    case TentTypeV1.TWIN_ROOM:
      return 2_100;
    case TentTypeV1.FAMILY_ROOM:
      return 2_500;
    case TentTypeV1.FAMILY_ROOM_PLUS:
      return 3_500;
    case TentTypeV1.DORM_SINGLE:
      return 1_750;
    case TentTypeV1.DORM_BUNK:
      return 1_500;
  }
};

export const getBathingPrice = (tentType: TentTypeV1) => {
  return getPrice(tentType) + 500;
};

export const getLowestPricedTent = () => {
  return Object.values(TentTypeV1).reduce((lowest, tentType) => {
    const price = getPrice(tentType);
    return price < getPrice(lowest) ? tentType : lowest;
  });
};

export const getLowestPriceTag = () => {
  const tentType = getLowestPricedTent();
  return getPriceTag(tentType);
};

export const getPriceTag = (tentType: TentTypeV1, includeRsPrefix = true) => {
  const price = getPrice(tentType);
  return `${includeRsPrefix ? 'RS. ₹' : '₹'}${price.toLocaleString()}`;
};

export const getBathingPriceTag = (tentType: TentTypeV1, includeRsPrefix = true) => {
  const price = getBathingPrice(tentType);
  return `${includeRsPrefix ? 'RS. ₹' : '₹'}${price.toLocaleString()}`;
};
